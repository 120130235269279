import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  Edit,
  Create,
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
  Button,
  DateField,
  useRecordContext,
  FunctionField,
  Filter,
} from 'react-admin';
import { ContentCopy } from '@mui/icons-material';
import { useListContext } from 'react-admin';
import { STATUS_CHOICES } from './constants';
import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  TextField as TextFieldMui,
} from '@mui/material';
import { getWalletsAndAmount, setWithdrawTxUrl, updateWithdrawStatus } from '../../api-service';
import { sliceAddress } from './utils';

const CustomBulkButtons = () => {
  const { selectedIds, data, refetch } = useListContext();
  const [isSetStatusDialogOpen, setSetStatusDialogOpen] = useState(false);
  const [isAddTxIdDialogOpen, setAddTxIdDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [txUrl, setTxUrl] = useState('');
  const [totalSum, setTotalSum] = useState(0);

  const onOpenSetStatusDialog = () => setSetStatusDialogOpen(true);
  const onCloseSetStatusDialog = () => setSetStatusDialogOpen(false);

  const onOpenAddTxIdDialog = () => setAddTxIdDialogOpen(true);
  const onCloseAddTxIdDialog = () => setAddTxIdDialogOpen(false);

  useEffect(() => {
    const totalAmount = data
      .filter((item) => selectedIds.includes(item?.id))
      .reduce((sum, record) => {
        const amount = parseFloat(record?.amount) || 0;
        return sum + amount;
      }, 0);
    setTotalSum(totalAmount);
  }, [selectedIds, data]);

  const handleSubmitStatus = async () => {
    try {
      await updateWithdrawStatus({ queryParams: { ids: selectedIds }, data: { status: selectedStatus } });

      refetch?.();
      setSetStatusDialogOpen(false);
      setSelectedStatus('');
    } catch (err) {
      console.error('Failed to update status', err);
    }
  };
  const onGetWallets = async () => {
    try {
      const result = await getWalletsAndAmount({ queryParams: { ids: selectedIds } });
      const csvContent = result.data;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'wallets.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Failed to fetch wallets', err);
    }
  };

  const handleSubmitTxUrl = async () => {
    try {
      await setWithdrawTxUrl({
        queryParams: { ids: selectedIds },
        data: { transactionUrl: txUrl },
      });

      refetch?.();
      onCloseAddTxIdDialog();
      setTxUrl('');
    } catch (err) {
      console.error('Failed to update transaction URL', err);
    }
  };

  return (
    <>
      <Box display="flex" gap={'24px'} mt={'8px'} alignItems={'center'} p={0}>
        <Typography mr="auto"> Total Amount: {totalSum.toLocaleString('en-US')}</Typography>
        <Button label="Set Status" onClick={onOpenSetStatusDialog} color="inherit" />
        <Button label="Get Wallets" onClick={onGetWallets} color="inherit" />
        <Button label="Add TXid" onClick={onOpenAddTxIdDialog} color="inherit" />
      </Box>
      <Dialog open={isSetStatusDialogOpen} onClose={onCloseSetStatusDialog}>
        <DialogTitle>Set Status for Selected</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select value={selectedStatus} label="Status" onChange={(e) => setSelectedStatus(e.target.value)}>
              {STATUS_CHOICES.map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={onCloseSetStatusDialog} />
          <Button label="Submit" onClick={handleSubmitStatus} disabled={!selectedStatus} />
        </DialogActions>
      </Dialog>
      <Dialog open={isAddTxIdDialogOpen} onClose={onCloseAddTxIdDialog}>
        <DialogTitle>Add Transaction URL</DialogTitle>
        <DialogContent>
          <TextFieldMui
            fullWidth
            label="Transaction URL"
            value={txUrl}
            onChange={(e) => setTxUrl(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={onCloseAddTxIdDialog} />
          <Button label="Submit" onClick={handleSubmitTxUrl} disabled={!txUrl} />
        </DialogActions>
      </Dialog>
    </>
  );
};

const AffiliateWithdrawRequestFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Status" source="status" choices={STATUS_CHOICES} alwaysOn />
  </Filter>
);

const WalletFieldWithCopy = () => {
  const record = useRecordContext();

  if (!record) return null;

  const shortWallet = sliceAddress(record.wallet, 6);

  const handleCopy = () => {
    navigator.clipboard.writeText(record.wallet || '');
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body2">{shortWallet}</Typography>
      <Tooltip title="Copy">
        <IconButton size="small" onClick={handleCopy}>
          <ContentCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const TransactionUrlWithCopy = () => {
  const record = useRecordContext();
  if (!record?.transaction_url) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(record.transaction_url);
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <a href={record.transaction_url} target="_blank" rel="noopener noreferrer">
        <Typography variant="body2" color="primary">
          TX Link
        </Typography>
      </a>
      <Tooltip title="Copy URL">
        <IconButton size="small" onClick={handleCopy}>
          <ContentCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const AffiliateWithdrawRequestsList = (props) => (
  <List {...props} perPage={100} exporter={false} hasCreate filters={<AffiliateWithdrawRequestFilter />}>
    <Datagrid bulkActionButtons={<CustomBulkButtons />}>
      <TextField source="id" />
      <DateField source="created_at" />
      <TextField source="user_id" />
      <FunctionField label="Wallet" render={() => <WalletFieldWithCopy />} />
      <TextField source="amount" />
      <TextField source="status" />
      <FunctionField label="Transaction URL" render={() => <TransactionUrlWithCopy />} />
      <EditButton />
    </Datagrid>
  </List>
);

const Form = () => {
  return (
    <SimpleForm>
      <NumberInput source="user_id" />
      <TextInput source="amount" />
      <TextInput source="wallet" />
      <TextInput source="network" />
      <SelectInput source="status" choices={STATUS_CHOICES} />
      <TextInput source="transaction_url" />
      <DateInput source="paid_at" />
      <DateInput source="created_at" />
      <TextInput source="notes" multiline />
    </SimpleForm>
  );
};

export const AffiliateWithdrawRequestsEdit = () => {
  return (
    <Edit>
      <Form />
    </Edit>
  );
};

export const AffiliateWithdrawRequestsCreate = () => {
  return (
    <Create>
      <Form />
    </Create>
  );
};
