import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  required,
  ImageField,
  ImageInput,
  TabbedForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { imageInputAccept, SortingDirection } from '../../variables';
import { AppResourceNames } from '../../variables/app-resource-names';
import { TinymceEditor } from '../../inputs/tinymce-editor';
import { TranslatableInput } from '../../inputs/translatable-input';
import { helperTexts } from '../../variables/helper-texts';
import { useWatch } from 'react-hook-form';

export const SummaryTab = (props) => {
  const { isEdit, ...tabProps } = props;
  const logo = useWatch({ name: 'logo' });

  return (
    <TabbedForm.Tab label="Summary" {...tabProps}>
      <BooleanInput source="enabled" />
      <TextInput source="name" validate={required()} />

      {isEdit && (
        <>
          <ImageInput source="logo" accept={imageInputAccept}>
            <ImageField source="src" />
          </ImageInput>
          {typeof logo === 'string' && <ImageField source="logo" title="Logo" />}
        </>
      )}

      <TextInput source="key" validate={required()} helperText={helperTexts.keyInputValidation} />
      <ReferenceInput
        source="coin_id"
        reference={AppResourceNames.COINS}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteInput label="Ecosystem Coin" optionText="fullName" validate={required()} />
      </ReferenceInput>

      <ReferenceArrayInput
        source="blockchainIds"
        reference="token-platforms"
        sort={{ field: 'name', order: SortingDirection.ASC }}
      >
        <AutocompleteArrayInput optionText="name" label="Blockchains" />
      </ReferenceArrayInput>

      <ReferenceArrayInput
        source="tagIds"
        reference={AppResourceNames.ECOSYSTEM_TAGS}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>

      {isEdit && (
        <TranslatableInput>
          <TinymceEditor source="description" />
          <TextInput source="short_description" label="Short description" validate={required()} multiline={true} />
        </TranslatableInput>
      )}
    </TabbedForm.Tab>
  );
};
