import {
  List,
  Datagrid,
  EditButton,
  Edit,
  TextInput,
  SimpleForm,
  BulkDeleteWithUndoButton,
  useRecordContext,
  Create,
  required,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FunctionField,
  TextField,
  ImageInput,
  ImageField,
} from 'react-admin';
import { FormType, S3ImageDir } from '../variables';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { getLang } from '../utils/get-lang';
import { NumberInput } from '../inputs/number-input';
import { TranslatableInput } from '../inputs/translatable-input';
import { ApiMetricStatus, ApiMetricStatusMap } from '../variables/api-metrics-status';
import { getEnvs } from '../env-lib';

const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + S3ImageDir.API_METRICS;
const formatTitle = (title) => title?.replace(/%.*?%\s*/, '').trim() ?? '';

export const ApiMetricsList = (props) => (
  <List {...props} perPage={25}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="priority" />
      <FunctionField render={(record) => formatTitle(record.title)} />
      <EditButton />
    </Datagrid>
  </List>
);

const Form = (props: { type: FormType }) => {
  return (
    <SimpleForm>
      <TranslatableInput>
        <TextInput source="title" label="Name" validate={required()} defaultValue={null} />
        <TextInput source="description" label="Description" defaultValue={''} />
      </TranslatableInput>

      <FunctionField
        label="Icon Light"
        render={(record) =>
          record?.icon_white ? (
            <img src={imagesBasePath + record.icon_white} alt="image" style={{ maxHeight: '50px' }} />
          ) : null
        }
      />
      <ImageInput
        source="icon_white"
        label="Icon Light"
        accept={['image/svg+xml', 'image/png']}
        helperText={'Maximum size is 1MB'}
      >
        <ImageField source="src" title="Image" />
      </ImageInput>

      <FunctionField
        label="Icon Dark"
        render={(record) =>
          record?.icon_dark ? (
            <img src={imagesBasePath + record.icon_dark} alt="image" style={{ maxHeight: '50px' }} />
          ) : null
        }
      />
      <ImageInput
        source="icon_dark"
        label="Icon Dark"
        accept={['image/svg+xml', 'image/png']}
        helperText={'Maximum size is 1MB'}
      >
        <ImageField source="src" title="Image" />
      </ImageInput>

      <SelectInput
        source="status"
        choices={Object.values(ApiMetricStatus).map((name) => ({ id: name, name: ApiMetricStatusMap[name] }))}
        validate={required()}
      />
      <NumberInput
        source="priority"
        label="Priority"
        helperText={'Приоритет, в соответствии с которым будут выводиться блоки'}
      />

      <ReferenceArrayInput source="endpointIds" reference="api-endpoints" resource="api-endpoints" perPage={1000}>
        <SelectArrayInput optionText="path" defaultValue={[]} />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

const ApiMetricsTitle = () => {
  const record = useRecordContext();
  return <span>{formatTitle(getLang(record?.locales))}</span>;
};

export const ApiMetricsEdit = () => {
  return (
    <Edit title={<ApiMetricsTitle />}>
      <Form type={FormType.EDIT} />
    </Edit>
  );
};

export const ApiMetricsCreate = () => (
  <Create>
    <Form type={FormType.CREATE} />
  </Create>
);
