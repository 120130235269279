import siteIconUrl from './site.svg';
import xIconUrl from './x.svg';
import telegramIconUrl from './telegram.svg';

export const contacts = [
  {
    iconUrl: siteIconUrl,
    name: 'CryptoRank.io/Funding-Rounds',
  },
  {
    iconUrl: xIconUrl,
    name: 'CryptoRank_VCs',
  },
  {
    iconUrl: telegramIconUrl,
    name: 'CryptoRank_Fundraising',
  },
];
