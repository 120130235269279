import logoUrl from './logo.svg';
import { ContactList, ContactListItem, FooterContainer } from './styled';
import { contacts } from './constants';

export const MediaPostFooter = () => {
  return (
    <FooterContainer>
      <ContactList>
        {contacts.map(({ iconUrl, name }) => (
          <ContactListItem key={name}>
            <img src={iconUrl} alt={name} />
            <p>{name}</p>
          </ContactListItem>
        ))}
      </ContactList>

      <img src={logoUrl} alt="Cryptorank logo" />
    </FooterContainer>
  );
};
