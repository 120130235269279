export enum FundingRoundTypes {
  Angel = 'ANGEL',
  PreSale = 'PRESALE',
  Seed = 'SEED',
  ExtendedSeed = 'Extended Seed',
  PreSeed = 'PRE SEED',
  ExtendedPreSeed = 'Extended Pre-Seed',
  Strategic = 'STRATEGIC',
  PreSeriesA = 'Pre-Series A',
  SeriesA = 'SERIES A',
  ExtendedSeriesA = 'Extended Series A',
  ExtendedSeriesB = 'Extended Series B',
  PreSeriesB = 'Pre-Series B',
  SeriesB = 'SERIES B',
  SeriesC = 'SERIES C',
  ExtendedSeriesC = 'Extended Series C',
  SeriesD = 'SERIES D',
  ExtendedSeriesD = 'Extended Series D',
  SeriesE = 'Series E',
  SeriesF = 'Series F',
  SeriesG = 'Series G',
  SeriesH = 'Series H',
  PrivateTokenSase = 'Private Token Sale',
  IPO = 'IPO',
  PostIPO = 'Post-IPO',
  PostIPODebt = 'Post-IPO Debt',
  DebtFinancing = 'Debt Financing',
  TreasuryDiversification = 'Treasury Diversification',
  Undisclosed = 'Undisclosed',
  Grant = 'Grant',
  MA = 'M&A',
  OTCPurchase = 'OTC Purchase',
  Incubation = 'Incubation',
}
