import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  BooleanInput,
  RefreshButton,
  Filter,
  BulkDeleteWithUndoButton,
  useRecordContext,
  maxLength,
  SimpleForm,
  required,
} from 'react-admin';
import { SortingDirection } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { TrimOnBlurInput } from '../inputs/trim-on-blur-input';
import { helperTexts } from '../variables/helper-texts';
import { TranslatableInput } from 'src/inputs/translatable-input';
import { useUpdateName } from 'src/hooks';

const TagsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);
export const CoinTagList = (props) => (
  <List sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<TagsFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const CoinTagTitle = () => {
  const record = useRecordContext();
  return <span>Coin Tag '{record?.name}'</span>;
};

const TranslatableFields = () => {
  const handleChangeName = useUpdateName();

  return (
    <TranslatableInput langSource="locale">
      <TrimOnBlurInput source="name" validate={[maxLength(64), required()]} onChange={handleChangeName} />
      <TextInput source="description" validate={[maxLength(256)]} />
    </TranslatableInput>
  );
};

const Form = () => {
  const isEdit = typeof useRecordContext()?.id === 'number';

  return (
    <SimpleForm toolbar={<DefaultToolBar />}>
      <TextInput required source="slug" validate={[maxLength(64)]} helperText={helperTexts.keyInputValidation} />
      <TranslatableFields />
      <BooleanInput source="enabled" defaultValue={true} />
      {isEdit && <BooleanInput label="ICO List" source="isIcoList" />}
    </SimpleForm>
  );
};

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const CoinTagEdit = () => (
  <Edit actions={<EditActions />} title={<CoinTagTitle />}>
    <Form />
  </Edit>
);

export const CoinTagCreate = () => (
  <Create>
    <Form />
  </Create>
);
