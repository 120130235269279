import { FundingRound } from 'src/types';
import { getFundingRoundFundraising } from 'src/api-service';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNotify } from 'react-admin';
import { useSimpleFormIteratorItem } from 'ra-ui-materialui';

export const useFundingRound = (): FundingRound | null => {
  const notify = useNotify();
  const roundArrayIndex = useSimpleFormIteratorItem().index;
  const fundingRoundIndex = useFormContext().getValues().fundingRounds[roundArrayIndex].id;
  const [fundingRound, setFundingRound] = useState<FundingRound>();

  useEffect(() => {
    console.log({ fundingRoundIndex });
    getFundingRoundFundraising(fundingRoundIndex)
      .then(setFundingRound)
      .catch((error) => {
        if (error instanceof Error) {
          notify(error.message, { type: 'error' });
        }
      });
  }, [fundingRoundIndex, notify]);

  if (!fundingRound) {
    return null;
  }

  return fundingRound;
};
