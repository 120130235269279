import { List, Header, FallbackMessage, Title, Divider } from './styled';
import { FundListItem } from '../fund-list-item';
import { FundraisingFund } from 'src/types';

type Props = {
  title: string;
  funds: FundraisingFund[];
};

export const FundList = ({ title, funds }: Props) => {
  return (
    <div>
      <Header>
        <Title>{title}</Title>
        <Divider />
      </Header>

      <List isGrid={funds.length > 2}>
        {funds.length !== 0 ? (
          funds.map((fund) => <FundListItem fund={fund} isOnlyOne={funds.length === 1} key={fund.slug} />)
        ) : (
          <FallbackMessage>TBD</FallbackMessage>
        )}
      </List>
    </div>
  );
};
