import { Dialog, Button } from '@mui/material';
import { useState } from 'react';
import { MediaPostModalContent } from '../media-post-modal-content';

type Props = {
  className?: string;
};

export const MediaPost = ({ className }: Props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)} className={className}>
        Create Post
      </Button>
      {isOpen && (
        <Dialog open={isOpen} maxWidth="lg">
          <MediaPostModalContent closeModal={() => setOpen(false)} />
        </Dialog>
      )}
    </>
  );
};
