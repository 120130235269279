import { InvestorCard } from './styled';
import { FundraisingFund } from 'src/types';

type Props = {
  fund: FundraisingFund;
  isOnlyOne: boolean;
};

export const FundListItem = ({ fund, isOnlyOne }: Props) => {
  return (
    <InvestorCard isOnlyOne={isOnlyOne}>
      <img src={fund.logo} alt={fund.name} />
      <p>
        {fund.name}
        {fund.isLead && <span> ⚑&nbsp;Lead</span>}
      </p>
    </InvestorCard>
  );
};
