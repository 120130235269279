import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 40px 25px;
`;

export const ContactList = styled.ul`
  display: flex;
  gap: 25px;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ContactListItem = styled.li`
  p {
    font-size: 14px;
    color: #5460e1;
  }
`;
