export enum AppResourceNames {
  COINS = 'coins',
  ICO = 'ico',
  COIN_CATEGORY = 'coin-category',
  COIN_TAG = 'coin-tag',
  FUND_CATEGORIES = 'fund-categories',
  FUNDS = 'funds',
  EXCHANGES = 'exchanges',
  IDO_PLATFORMS = 'ido-platforms',
  TOKEN_PLATFORMS = 'token-platforms',
  TICKERS = 'tickers',
  SEO_TEMPLATES = 'seo-templates',
  COIN_MAPPINGS = 'coin-mappings',
  USERS = 'users',
  LINKS = 'links',
  MANAGERS = 'managers',
  ADMIN_LOG = 'admin-log',
  COUNTRIES = 'countries',
  COINGECKO_COINS = 'coingecko-coins',
  COINMARKETCAP_COINS = 'coinmarketcap-coins',
  IMAGE_HUB = 'image-hub',
  EXCHANGE_FEATURES = 'exchange-features',
  BANNERS = 'banners',
  MOBILE_LOCALIZATION = 'mobile-app-localization',
  NEWS_SOURCE = 'news-source',
  NEWS_TAG = 'news-tag',
  NEWS_CATEGORY = 'news-category',
  NEWS_LOGO = 'news-plug',
  MISSIONS = 'missions',
  WRITE_OFFS = 'write-offs',
  BATTLE = 'battle',
  GAMIFICATION_DASHBOARD = 'gamification-dashboard',
  SPONSORED_TOKEN_SALE = 'sponsored-token-sale',
  SPONSORED_FUNDING_ROUND = 'sponsored-funding-round',
  NEWS = 'news',
  COIN_TRENDING_SETTINGS = 'coin-trending-settings',
  TASKS = 'tasks',
  DEXSCREENER_EXCHANGES = 'dexscreener-exchanges',
  ON_CHAIN_TASK = 'on-chain-task',
  SPONSORED_FUNDS = 'sponsored-funds',
  YOUTUBE_VIDEOS = 'youtube-videos',
  MOBILE_APP_DOWNLOAD = 'mobile-app-download',
  MOBILE_APP_STORIES = 'mobile-app-stories',
  ARTICLE_AUTHORS = 'articles-authors',
  ARTICLE_SUBCATEGORIES = 'articles-subcategories',
  ARTICLE_CATEGORIES = 'articles-categories',
  ARTICLES = 'articles',
  CONTENT_TAGS = 'content-tags',
  PESONS = 'persons',
  PESONS_JOBS = 'persons-jobs',
  PERSONS_CATEGORIES = 'persons-categories',
  DROP_HUNTING_ACTIVITIES = 'drop-hunting-activities',
  DROP_HUNTING_REWARDS = 'drop-hunting-rewards',
  DROP_HUNTING_TASK_TYPES = 'drop-hunting-task-types',
  ECOSYSTEMS = 'ecosystems',
  ECOSYSTEM_TAGS = 'ecosystem-tags',
  LI_FI_DASHBOARD = 'li-fi-dashboard',
  PARTNERSHIP_CONTACTS = 'company-contacts',
  API_TARIFFS = 'api-tariffs',
  API_ENDPOINTS = 'api-endpoints',
  API_METRICS = 'api-metrics',
  TMA_TASKS = 'tma/task',
  TMA_POOL = 'tma/pool',
  TMA_QUIZ = 'tma/quiz',
  TMA_QUIZ_CONFIG = 'tma/quiz/configuration',
  API_SUBSCRIPTIONS = 'api-subscriptions',
  AFFILIATE_WITHDRAW_REQUESTS = 'affiliate-withdraws',
  WEB_REDIRECTS = 'web-redirects'
}
