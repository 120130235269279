import styled from 'styled-components';

export const LoaderContainer = styled.div<{ hasBackground: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.hasBackground && 'rgba(0, 0, 0, 0.4)'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SnapshotContainer = styled.div`
  min-width: 300px;
  min-height: 200px;
`;
