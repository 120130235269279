import { httpClient } from './dataProvider';
import { getEnvs } from './env-lib';
import { LifiDashboardInfo, LifiParams } from './resources/li-fi-dashboard/types';
import { FundingRound } from './types';

type QueryParams = {
  method?: string;
  queryParams?: Record<string, any>;
  data?: any;
};

const BASE_APP_API = getEnvs().REACT_APP_API_SERVER + '/v0/admin';

const sendRequest = (path: string, params: QueryParams = {}) => {
  const { queryParams = {}, method = 'GET', data } = params;

  const queryString = Object.keys(queryParams)
    .filter((k) => !(queryParams[k] === undefined) && !Number.isNaN(queryParams[k]))
    .map((k) => k + '=' + queryParams[k])
    .join('&');

  const encodedPath = encodeURI(path);
  const url = `${BASE_APP_API}/${encodedPath}${queryString ? '?' + queryString : ''}`;
  const options: RequestInit = {
    method,
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  return httpClient(url, options).then(({ json }) => json);
};

export const getDashboard = () => {
  return sendRequest('dashboard');
};

export const getDashboardGamification = () => {
  return sendRequest('gamification-dashboard').then((data) => data.data);
};

export const getManagerStatistics = ({ from, to }: { from: string; to: string }) => {
  return sendRequest('dashboard/managers', { queryParams: { from, to } }).then(({ data }) => data);
};

export const getApiPlans = () => {
  return sendRequest('dashboard/api-users-count').then(({ data }) => data);
};

export const createManyPromoCodes = (params: { writeOffId: number; promocodes: { data: string }[] }) => {
  return sendRequest(`write-offs/${params.writeOffId}/promocodes`, {
    method: 'POST',
    data: params.promocodes,
  });
};

export const updateManyPromoCodes = (params: { writeOffId: number; promocodes: { data: string }[] }) => {
  return sendRequest(`write-offs/${params.writeOffId}/promocodes`, { method: 'PUT', data: params.promocodes });
};

export const deleteManyPromoCodes = (params: { writeOffId: number; promocodes: string[] }) => {
  return sendRequest(`write-offs/${params.writeOffId}/promocodes/delete`, { method: 'POST', data: params.promocodes });
};

export const getExchangePortfolio = (params: { exchangeId: number }) => {
  return sendRequest(`exchange-portfolio-wallets/${params.exchangeId}`, {
    method: 'GET',
  }).then((data) => data);
};

export const updateExchangePortfolio = (params: { exchangeId: number; exchangePortfolio }) => {
  return sendRequest(`exchange-portfolio-wallets/${params.exchangeId}`, {
    method: 'POST',
    data: params.exchangePortfolio,
  });
};

export const getLeaderboard = (params: { id: number }) => {
  return sendRequest(`battle/leaderboard/${params.id}`, { method: 'GET' }).then((data) => data);
};

export const getAccrueRewards = (params: { id: number; rewards: { data: any }[] }) => {
  return sendRequest(`battle/accrue-rewards/${params.id}`, { method: 'POST' }).then((data) => data);
};

export const getNewsLogos = () => {
  return sendRequest(`news-plug`, { method: 'GET' });
};

export const removeNewsLogo = (logo) => {
  return sendRequest(`news-plug/${logo}`, { method: 'DELETE' });
};

export const addNewsLogo = (data: { image: string; name: string }) => {
  return sendRequest(`news-plug`, { method: 'PUT', data });
};

export const getCoinVesting = (params: { coinId: number }) => {
  return sendRequest(`coin-allocation/${params.coinId}/vesting`, { method: 'GET' });
};

export const postCoinVesting = (params: { coinId: number; formData }) => {
  return sendRequest(`coin-allocation/${params.coinId}/vesting`, { method: 'POST', data: params.formData });
};

export const getCoinAllocationsList = (params: { coinId: number }) => {
  return sendRequest(`coin-allocation/${params.coinId}`, { method: 'GET' });
};

export const postCoinAllocation = (params: { coinId: number; formData }) => {
  return sendRequest(`coin-allocation/${params.coinId}`, { method: 'POST', data: params.formData });
};

export const deleteCoinAllocation = (params: { coinId: number; allocationId: number }) => {
  return sendRequest(`coin-allocation/${params.coinId}/${params.allocationId}`, { method: 'DELETE' });
};

export const parseAllocationList = (params: { coinId: number; dropsSlug: string }) => {
  return sendRequest(`coin-allocation/${params.coinId}/${params.dropsSlug}`, { method: 'POST' });
};

export const getSmartContractMethods = (params: { address: string; platformIds: string }) => {
  return sendRequest(`on-chain-task/smart-contract-methods/${params.address}?platformIds=${params.platformIds}`, {
    method: 'GET',
  });
};

export const getCurrentAppVersion = () => {
  return sendRequest('mobile-uploader/current-version').then(({ s3Links }) => s3Links);
};

export const getLiFiDashboard = (params: LifiParams): Promise<LifiDashboardInfo> => {
  return sendRequest('li-fi-dashboard', { method: 'GET', queryParams: params });
};

export const getPartnershipContacts = () => {
  return sendRequest('company-contacts');
};

export const getQuizPresets = () => {
  return sendRequest('tma/quiz/configuration/presets');
};

export const getCountries = () => {
  return sendRequest('countries');
};

export const updateWithdrawStatus = ({ queryParams, data }) => {
  return sendRequest(`affiliate-withdraws/set-status`, { method: 'POST', queryParams, data });
};

export const setWithdrawTxUrl = ({ queryParams, data }) => {
  return sendRequest(`affiliate-withdraws/set-transaction-url`, { method: 'POST', queryParams, data });
};

export const getWalletsAndAmount = ({ queryParams }) => {
  return sendRequest('affiliate-withdraws/wallets', { method: 'GET', queryParams });
};

export const sendFundingRoundMediaPost = (data: { photo: string; caption: string }) => {
  return sendRequest('funding-rounds/fundraising/post', { method: 'POST', data });
};

export const getFundingRoundFundraising = (id: number): Promise<FundingRound> => {
  return sendRequest(`funding-rounds/${id}/fundraising`);
};
