import styled, { createGlobalStyle } from 'styled-components';
import background from './background.svg';

export const PostContainer = styled.div`
  font-family: 'Noto Sans', sans-serif;
  background-image: url(${background});
  background-size: cover;
  width: 960px;
  color: #000;
  overflow: hidden;

  p,
  h3 {
    margin: 0;
  }
`;

export const InvestorsContainer = styled.div`
  margin: 35px 31px 5px;
`;

export const LocalNotoSansFontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/NotoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/NotoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/NotoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/NotoSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/NotoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans Display';
    src: url('/fonts/NotoSansDisplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
`;
