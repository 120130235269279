import { format, hoursToSeconds, secondsToHours } from 'date-fns';
import { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  SimpleForm,
  Edit,
  BooleanInput,
  NumberInput,
  ImageField,
  Create,
  SelectInput,
  TopToolbar,
  SaveButton,
  FormDataConsumer,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { getCountries } from '../../api-service';
import { SortingDirection } from '../../variables';

import { taskGroupChoices, taskTypeChoices } from './constants';
import { TaskType } from './types';

export const transformTaskData = async (data, isApprovedCountriesChoose: boolean) => {
  if (isApprovedCountriesChoose && data.forbidden_countries.length > 0) {
    const allCountries = await getCountries();
    const forbiddenCountryIds = allCountries
      .filter(({ id }) => !data.forbidden_countries.includes(id))
      .map(({ id }) => id);
    return { ...data, forbidden_countries: forbiddenCountryIds };
  }

  return data;
};

const CountrySelect = ({
  isApprovedCountriesChoose,
  setIsApprovedCountriesChoose,
}: {
  isApprovedCountriesChoose: boolean;
  setIsApprovedCountriesChoose: (value: boolean) => void;
}) => {
  return (
    <>
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <div>
          <ReferenceArrayInput
            source={'forbidden_countries'}
            reference="countries"
            sort={{ field: 'id', order: SortingDirection.ASC }}
          >
            <AutocompleteArrayInput label="Countries" source="forbidden_countries" optionText="name" optionValue="id" />
          </ReferenceArrayInput>

          <input
            style={{ marginRight: 8 }}
            type="checkbox"
            checked={isApprovedCountriesChoose}
            onChange={() => setIsApprovedCountriesChoose(!isApprovedCountriesChoose)}
          />
          {isApprovedCountriesChoose ? 'Approved' : 'Forbidden'}
        </div>
      </div>
      <label>
        * If disabled then selected countries are excluded from the advertisement; if enabled then only selected
        countries will see advertisement.
      </label>
    </>
  );
};

export const TMATaskListList = (props) => {
  return (
    <List perPage={50} {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="Id" />
        <FunctionField
          label="Created at"
          sortBy="created_at"
          render={(record) => format(new Date(record.created_at), 'dd.MM.yyyy, HH:mm:ss')}
        />
        <TextField source="name" label="Name" />
        <TextField source="is_visible" label="Active" />
        <TextField source="claim_count" label="Claimed" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
const CustomToolbar = () => (
  <TopToolbar>
    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <SaveButton />
    </div>
  </TopToolbar>
);

export const TMATaskEdit = (props) => {
  const [isApprovedCountriesChoose, setIsApprovedCountriesChoose] = useState(false);

  const transform = async (data) => {
    return await transformTaskData(data, isApprovedCountriesChoose);
  };

  return (
    <Edit {...props} redirect={(basePath) => basePath} transform={transform}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="name" label="Name" />
        <ImageField source="icon_url" title="Icon" />
        <TextInput source="icon_url" title="Icon" />
        <NumberInput source="claim_limit" label="Claim Limit" />
        <SelectInput source="group" label="Task Group" choices={taskGroupChoices} />
        <BooleanInput source="is_visible" label="Is Visible" />
        <TextField source="id" label="ID" />
        <NumberInput source="reward" label="Reward" disabled />
        <NumberInput source="cooldown" label="Cooldown (hours)" format={secondsToHours} disabled />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.type === TaskType.PUBLICATION_STORY && (
                <>
                  <TextInput source="story_text" label="Text of Story" />
                  <TextInput source="story_media_file_url" label="Image of Story (url)" />
                </>
              )}
              {formData.type === TaskType.RAFFLE_QUEST && (
                <ReferenceInput source="cryptorank_quest_id" reference="write-offs" perPage={1000} />
              )}
              {formData.type === TaskType.QUIZ && <ReferenceInput source="quiz_id" reference="tma/quiz" />}
            </>
          )}
        </FormDataConsumer>
        <CountrySelect
          isApprovedCountriesChoose={isApprovedCountriesChoose}
          setIsApprovedCountriesChoose={setIsApprovedCountriesChoose}
        />
      </SimpleForm>
    </Edit>
  );
};

export const TMATaskCreate = (props) => {
  const [isCooldownEnabled, setCooldownEnabled] = useState(false);
  const [isStoryInputEnabled, setStoryInputEnabled] = useState(false);
  const [isQuizIdEnabled, setQuizIdEnabled] = useState(false);
  const [isRaffleEnabled, setRaffleEnabled] = useState(false);
  const [isApprovedCountriesChoose, setIsApprovedCountriesChoose] = useState(false);

  const handleTaskTypeChange = (e) => {
    setCooldownEnabled(e.target.value === TaskType.DAILY);
    setStoryInputEnabled(e.target.value === TaskType.PUBLICATION_STORY);
    setQuizIdEnabled(e.target.value === TaskType.QUIZ);
    setRaffleEnabled(e.target.value === TaskType.RAFFLE_QUEST);
  };

  const transform = async (data) => {
    return await transformTaskData(data, isApprovedCountriesChoose);
  };

  return (
    <Create {...props} redirect={(basePath) => basePath} transform={transform}>
      <SimpleForm>
        <TextInput source="name" label="Name" />
        <TextInput source="icon_url" title="Icon" />
        <NumberInput source="claim_limit" label="Claim Limit" />
        <BooleanInput source="is_visible" label="Is Visible" />
        <TextInput source="link_url" title="Link url" />
        <TextInput source="telegram_link_url" title="Telegram API Link" label="Telegram API Link" />
        <SelectInput source="type" label="Task Type" choices={taskTypeChoices} onChange={handleTaskTypeChange} />
        <SelectInput source="group" label="Task Group" choices={taskGroupChoices} />
        <NumberInput
          source="cooldown"
          label="Cooldown (hours)"
          disabled={!isCooldownEnabled}
          parse={hoursToSeconds}
          format={secondsToHours}
        />
        <NumberInput source="reward" label="Reward" />
        <TextInput source="story_text" label="Text of Story" disabled={!isStoryInputEnabled} />
        <TextInput source="story_media_file_url" label="Image of Story(url)" disabled={!isStoryInputEnabled} />
        {isQuizIdEnabled && <ReferenceInput source="quiz_id" reference="tma/quiz" />}

        {isRaffleEnabled && (
          <ReferenceInput source="cryptorank_quest_id" reference="write-offs" perPage={1000}>
            <AutocompleteInput
              optionText="id"
              label="Search for Raffle"
              filterToQuery={(searchText) => ({ id: searchText })}
            />
          </ReferenceInput>
        )}
        <CountrySelect
          isApprovedCountriesChoose={isApprovedCountriesChoose}
          setIsApprovedCountriesChoose={setIsApprovedCountriesChoose}
        />
      </SimpleForm>
    </Create>
  );
};
