import { FundingRoundTypes } from 'src/variables/funding-round-types';
import { capitalizeEveryWord } from './string';

export const formatFundingRoundTypes = (type: FundingRoundTypes | string) => {
  if (type === FundingRoundTypes.PreSeed) {
    return 'Pre-Seed';
  }

  if (
    [
      FundingRoundTypes.ExtendedSeed,
      FundingRoundTypes.ExtendedPreSeed,
      FundingRoundTypes.PreSeriesB,
      FundingRoundTypes.PreSeriesA,
      FundingRoundTypes.IPO,
      FundingRoundTypes.PostIPO,
      FundingRoundTypes.PostIPODebt,
      FundingRoundTypes.PrivateTokenSase,
      FundingRoundTypes.TreasuryDiversification,
      FundingRoundTypes.MA,
      FundingRoundTypes.OTCPurchase,
      FundingRoundTypes.Undisclosed,
      FundingRoundTypes.Incubation,
    ].includes(type as FundingRoundTypes)
  ) {
    return type;
  }

  return capitalizeEveryWord(type);
};
