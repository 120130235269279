import { FC } from 'react';
import { TabbedForm, TextInput } from 'ra-ui-materialui';
import { TranslatableInput } from '../../inputs/translatable-input';

export const OtherTab: FC = (props) => {
  return (
    <TabbedForm.Tab label="other" {...props}>
      <TranslatableInput langSource="locale">
        <TextInput multiline={true} label="Notification" source="notification" />
      </TranslatableInput>
      <TextInput multiline={true} label="Internal Notes" source="content.notes" />
    </TabbedForm.Tab>
  );
};
