import { ChangeEvent, FC, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  FunctionField,
  ImageInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'ra-ui-materialui';
import { Button } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { required } from 'react-admin';
import { RichTextInputCustomToolbar } from '../../toolbars/rich-text-input-toolbar';
import { imageInputAccept, SortingDirection } from '../../variables';
import { InlineRow } from './styled';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { icoScreenshotsPath } from './constants';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { NumberInput } from '../../inputs/number-input';
import { FundingRounds } from './funding-rounds';
import { Crowdsales } from './crowdsales';
import { Launchpools } from './launchpools';
import { useFormContext, useWatch } from 'react-hook-form';
import { NodeSales } from './node-sales';
import { TranslatableInput } from '../../inputs/translatable-input';

function HiddenFields(props) {
  const [show, setShow] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      <Button type="button" onClick={() => setShow(!show)}>
        {show ? 'Hide' : 'Show'} old fields
      </Button>
      <div style={{ width: '100%', display: show ? 'block' : 'none' }}>{props.children}</div>
    </div>
  );
}

export const CrowdsalesTab: FC<any> = (props) => {
  const isAdmin = () => getRole() === UserRole.ADMIN;

  const sponsoredIcoSource = 'content.icoData.isSponsored';
  const sponsoredStartDateSource = 'content.icoData.sponsoredStartDate';
  const sponsoredEndDateSource = 'content.icoData.sponsoredEndDate';

  const { setValue } = useFormContext();
  const isSponsored = useWatch({ name: sponsoredIcoSource });
  const hasNodeSales = useWatch({ name: 'has_node_sales' });

  if (props.hidden) {
    return <TabbedForm.Tab label="Crowdsales" {...props} />;
  }

  const onChangeHasNodeSale = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      return;
    }

    if (!hasNodeSales) {
      console.log('pass');
      setValue('nodeSale', undefined, { shouldDirty: true });
    }
  };

  const onChangeSponsored = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      return;
    }

    setValue(sponsoredStartDateSource, undefined, { shouldDirty: true });
    setValue(sponsoredEndDateSource, undefined, { shouldDirty: true });
  };

  return (
    <TabbedForm.Tab label="Crowdsales" {...props}>
      <BooleanInput label="Has Funding rounds" source="hasFundingRounds" />
      <BooleanInput label="Has crowdsales" source="isIco" />
      <BooleanInput label="Has Node Sale" source="has_node_sales" onChange={onChangeHasNodeSale} />
      <BooleanInput label="Has launchpools" source="hasLaunchpools" />
      <BooleanInput label="Sponsored ICO" source={sponsoredIcoSource} onChange={onChangeSponsored} />
      {isSponsored && (
        <InlineRow>
          <DateInput source={sponsoredStartDateSource} label="Start date" validate={required()} />
          <DateInput source={sponsoredEndDateSource} label="End date" validate={required()} />
        </InlineRow>
      )}
      <BooleanInput
        disabled={!isAdmin()}
        label="Don't calculate in IDO Platforms Rank and ROI"
        source="content.icoData.disableCalculateCrowdsales"
      />
      <HiddenFields>
        <InlineRow>
          <NumberInput
            style={{ width: '250px' }}
            label="Sold on Private/Pre-sale USD"
            source="content.icoData.soldOnPresale.USD"
          />
          <NumberInput
            style={{ width: '250px' }}
            label="Sold on Private/Pre-sale BTC"
            source="content.icoData.soldOnPresale.BTC"
          />
          <NumberInput
            style={{ width: '250px' }}
            label="Sold on Private/Pre-sale ETH"
            source="content.icoData.soldOnPresale.ETH"
          />
          <NumberInput
            style={{ width: '250px' }}
            label="Sold on Private/Pre-sale NEO"
            source="content.icoData.soldOnPresale.NEO"
          />
        </InlineRow>
        <InlineRow>
          <NumberInput label="Hard Cap USD" source="content.icoData.hardCap.USD" />
          <NumberInput label="Hard Cap BTC" source="content.icoData.hardCap.BTC" />
          <NumberInput label="Hard Cap ETH" source="content.icoData.hardCap.ETH" />
        </InlineRow>
        <InlineRow>
          <NumberInput label="Soft Cap USD" source="content.icoData.softCap.USD" />
          <NumberInput label="Soft Cap BTC" source="content.icoData.softCap.BTC" />
          <NumberInput label="Soft Cap ETH" source="content.icoData.softCap.ETH" />
        </InlineRow>
        <InlineRow>
          <NumberInput label="Raised Total USD" source="content.icoData.raised.USD" />
          <NumberInput label="Raised Total BTC" source="content.icoData.raised.BTC" />
          <NumberInput label="Raised Total ETH" source="content.icoData.raised.ETH" />
        </InlineRow>
        <InlineRow>
          <NumberInput label="Raised Public Sale USD" source="content.icoData.raisedPublic.USD" />
          <NumberInput label="Raised Private/Pre-sale USD" source="content.icoData.raisedPrivate.USD" />
        </InlineRow>
        <TextInput multiline={true} label="When token issue" source="content.icoData.tokenIssue" />
        <InlineRow>
          <NumberInput label="Tokens for public sale" source="content.icoData.tokensForPublicSale" />
          <NumberInput label="Tokens for Private/Pre-sale" source="content.icoData.tokensForPrivateSale" />
          <NumberInput label="Tokens for sale" source="content.icoData.tokensForSale" />
        </InlineRow>
      </HiddenFields>
      <SelectInput
        label="Token Role"
        source="content.icoData.tokenRole"
        choices={['Utility', 'Security'].map((name) => ({ id: name, name }))}
      />
      <NumberInput label="Tokens for sale" source="content.icoData.tokensForSale" />
      <NumberInput label="Initial Circulation" source="initialSupply" />
      <NumberInput
        label="Listing Price"
        source="listingPrice"
        helperText="Если значение заполнено, оно используется для подсчёта Initial Market Cap; если нет, то берётся максимальная цена публичного раунда."
        FormHelperTextProps={{ style: { width: 460, marginLeft: 0 } }}
      />
      <ImageInput
        source={icoScreenshotsPath}
        label="Screenshots"
        accept={imageInputAccept}
        multiple
        helperText="Название должно быть не длиннее 20 символов, размер изображения не более 1Мб, формат изображения - png/jpeg"
        maxSize={1048576}
      >
        <FunctionField
          source="data"
          title="description"
          render={(record) => (
            <img
              src={record.src ?? record.data ?? record.imageUrl}
              alt="image"
              style={{ maxHeight: '170px', marginRight: '10px' }}
            />
          )}
        />
      </ImageInput>

      <FundingRounds />

      <Crowdsales />

      {hasNodeSales && <NodeSales />}

      <Launchpools />

      <TranslatableInput langSource="locale">
        <RichTextInput
          label="About"
          source="icoDataDescription"
          toolbar={<RichTextInputCustomToolbar />}
          fullWidth={true}
        />
      </TranslatableInput>

      <ReferenceArrayInput
        source="content.icoData.cantParticipateCountries"
        reference="countries"
        perPage={300}
        sort={{ field: 'name', order: SortingDirection.ASC }}
      >
        <SelectArrayInput optionText="name" label="Can't Participate" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="content.icoData.teamFromCountries"
        reference="countries"
        perPage={300}
        sort={{ field: 'name', order: SortingDirection.ASC }}
      >
        <SelectArrayInput optionText="name" label="Team from" />
      </ReferenceArrayInput>
      <ArrayInput label="Additional links" source="content.icoData.additionalLinks">
        <ArrayInputIterator>
          <TextInput multiline={true} source="href" label="Hyper Reference" validate={[required()]} />
          <TextInput multiline={true} source="title" label="Title" validate={[required()]} />
          <TextInput multiline={true} source="comment" label="Comment" />
        </ArrayInputIterator>
      </ArrayInput>
    </TabbedForm.Tab>
  );
};
