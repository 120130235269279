import numeral from 'numeral';
import { getEnvs } from 'src/env-lib';
import { formatFundingRoundTypes } from 'src/utils/funding-rounds';
import { FundingRoundTypes } from 'src/variables/funding-round-types';
import { escapeMarkdown } from 'src/utils/markdown';
import { FundingRound } from 'src/types';

export const formatRoundType = (roundType: FundingRoundTypes | null) => {
  let roundName = 'Funding';

  if (roundType) {
    roundName = formatFundingRoundTypes(roundType);
  }

  const affix = roundType !== FundingRoundTypes.MA ? ' Round' : '';

  return roundName + affix;
};

export const generateProjectLink = (coinKey: string, tab: 'ico' | 'price' = 'price') => {
  return `${getEnvs().REACT_APP_API_SERVER}/${tab}/${coinKey}`;
};

const generateInvestorLink = (investorKey: string) => {
  return getEnvs().REACT_APP_API_SERVER + '/funds/' + investorKey;
};

export const formatUsd = (number: number) => {
  return numeral(number).format('$0.[0]a').toUpperCase();
};

export const generateTelegramMarkdown = ({
  name,
  crowdsalesType,
  key,
  raise,
  valuation,
  shortDescription,
  funds,
}: FundingRound): string => {
  const formattedProjectLink = escapeMarkdown(generateProjectLink(key, 'ico'));
  const formattedProject = `[${escapeMarkdown(name)}](${formattedProjectLink})`;
  const formattedRaised = raise ? escapeMarkdown(formatUsd(raise)) : '';
  const formattedStage = ` ${escapeMarkdown(formatRoundType(crowdsalesType))}⚡️`;
  const aboutText = shortDescription
    ? `📑 *About:* ${
        shortDescription.startsWith(name)
          ? formattedProject + escapeMarkdown(shortDescription.slice(name.length))
          : escapeMarkdown(shortDescription)
      }\n\n`
    : '';
  const valuationText = valuation ? `🏷 *Valuation:* ${escapeMarkdown(formatUsd(valuation))}\n\n` : '';
  const maxInvestorsToShow = 6;

  if (crowdsalesType === FundingRoundTypes.MA) {
    if (funds.length !== 1) {
      throw new Error('M&A rounds should have exactly one investor.');
    }
    const investor = funds[0];
    const investorLink = `[${escapeMarkdown(investor.name)}](${escapeMarkdown(generateInvestorLink(investor.slug))})`;
    const investorDescription = investor.shortDescription
      ? investor.shortDescription.startsWith(investor.name)
        ? investorLink + escapeMarkdown(investor.shortDescription.slice(investor.name.length))
        : escapeMarkdown(investor.shortDescription)
      : '';

    return (
      `*${escapeMarkdown(investor.name)} has acquired ${formattedProject}* ⚡️\n\n` +
      aboutText +
      `🤝 *Acquired by:* ${investorDescription}\n\n👉 ${formattedProjectLink}`
    );
  }

  let investorText = funds.map(({ name, slug, isLead }) => {
    return `[${escapeMarkdown(name)}](${escapeMarkdown(generateInvestorLink(slug))})${
      isLead ? escapeMarkdown(' (Lead)') : ''
    }`;
  });

  if (investorText.length > maxInvestorsToShow) {
    investorText = [...investorText.slice(0, maxInvestorsToShow - 1), escapeMarkdown('and other.')];
  } else if (investorText.length > 1) {
    investorText[investorText.length - 1] = `and ${investorText[investorText.length - 1]}`;
  }

  return (
    `${formattedProject} *${formattedRaised}${formattedStage}*\n\n` +
    aboutText +
    `🤝 *Investors:* ${investorText.join(', ')}\n\n` +
    valuationText +
    `👉 ${formattedProjectLink}`
  );
};
