export function capitalize(name: string): string {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function startCase(bannerType: string): string {
  return bannerType
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const capitalizeFirstLetter = (string: string, withLowerCase = true) => {
  if (typeof string !== 'string') {
    return '';
  }

  const subString = withLowerCase ? string.slice(1).toLowerCase() : string.slice(1);

  return string.charAt(0).toUpperCase() + subString;
};

export const capitalizeEveryWord = (string: string, withLowerCase = true) => {
  if (!string) {
    return '';
  }
  return string
    .split(' ')
    .map((str) => capitalizeFirstLetter(str, withLowerCase))
    .join(' ');
};
