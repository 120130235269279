import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  SimpleForm,
  BulkDeleteWithUndoButton,
  required,
  Toolbar,
  Filter,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { AdminSaveButton } from '../../buttons/save-btn';
import { NumberInput } from '../../inputs/number-input';

const WebRedirectsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="q_source" label="Source" alwaysOn />
    <TextInput source="q_destination" label="Destination" alwaysOn />
  </Filter>
);

export const WebRedirectsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<WebRedirectsFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="source" />
      <TextField source="destination" />
      <EditButton />
    </Datagrid>
  </List>
);

const WebRedirectsToolbar = () => {
  return (
    <Toolbar>
      <AdminSaveButton />
    </Toolbar>
  );
};

const Form = () => {
  return (
    <SimpleForm toolbar={<WebRedirectsToolbar />}>
      <TextInput source="source" validate={required()} />
      <TextInput source="destination" validate={required()} />
      <NumberInput source="priority" validate={required()} defaultValue={0} />
    </SimpleForm>
  );
};

export const WebRedirectsEdit = () => (
  <Edit>
    <Form />
  </Edit>
);

export const WebRedirectsCreate = () => (
  <Create>
    <Form />
  </Create>
);
