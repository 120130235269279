import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  Filter,
  BooleanInput,
  BulkDeleteWithUndoButton,
  useRecordContext,
  SimpleForm,
} from 'react-admin';
import { SortingDirection } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { TrimOnBlurInput } from '../inputs/trim-on-blur-input';
import { helperTexts } from '../variables/helper-texts';
import { TranslatableInput } from 'src/inputs/translatable-input';
import { useUpdateName } from 'src/hooks';

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const CoinCategoryList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<CategoryFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const CoinCategoryTitle = () => {
  const record = useRecordContext();
  return <span>Coin Category '{record?.name}'</span>;
};

const TranslatableFields = () => {
  const handleChangeName = useUpdateName();

  return (
    <TranslatableInput langSource="locale">
      <TrimOnBlurInput source="name" onChange={handleChangeName} />
    </TranslatableInput>
  );
};

const Form = () => {
  const isEdit = typeof useRecordContext()?.id === 'number';

  return (
    <SimpleForm toolbar={<DefaultToolBar />}>
      <TranslatableFields />
      {isEdit && (
        <>
          <TextInput source="slug" helperText={helperTexts.keyInputValidation} />
          <BooleanInput label="ICO List" source="isIcoList" />
        </>
      )}
    </SimpleForm>
  );
};

export const CoinCategoryEdit = () => (
  <Edit title={<CoinCategoryTitle />}>
    <Form />
  </Edit>
);

export const CoinCategoryCreate = () => (
  <Create>
    <Form />
  </Create>
);
