import { InvestorsContainer, LocalNotoSansFontStyles, PostContainer } from './styled';
import { FundList } from '../fund-list';
import { MediaPostHeader } from '../media-post-header';
import { MediaPostFooter } from '../media-post-footer';
import { FundingRound } from 'src/types';

type Props = {
  fundingRound: FundingRound;
};

export const MediaPostContent = ({ fundingRound }: Props) => {
  const { name, logo, raise, crowdsalesType, funds, shortDescription } = fundingRound;

  const angelFunds = funds.filter(({ isAngel }) => isAngel === true);
  const otherFunds = funds.filter(({ isAngel }) => isAngel === false);

  return (
    <>
      <LocalNotoSansFontStyles />
      <PostContainer>
        <MediaPostHeader
          name={name}
          logo={logo}
          shortDescription={shortDescription}
          raise={raise}
          crowdsalesType={crowdsalesType}
        />
        <InvestorsContainer>
          {(otherFunds.length !== 0 || angelFunds.length === 0) && <FundList title="Investors" funds={otherFunds} />}
          {angelFunds.length !== 0 && <FundList title="Angels" funds={angelFunds} />}
        </InvestorsContainer>
        <MediaPostFooter />
      </PostContainer>
    </>
  );
};
