import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Children, FC, PropsWithChildren, SyntheticEvent, cloneElement, isValidElement, useState } from 'react';
import { Locale } from '../variables';
import { TextInput } from 'ra-ui-materialui';
import { capitalize } from '../utils/string';
import { useFormContext } from 'react-hook-form';

type Props = PropsWithChildren<{
  langSource?: string;
}>;

/**
 * Аналог одноимённого компонента из библиотеки react-admin, но работает со следующим типом данных:
 *
 * @example
 * type Languages = {
 *   lang: Locale; // lang - изменяемый параметр langSource
 *   [key in string]: any;
 * }
 */
export const TranslatableInput: FC<Props> = (props) => {
  const { langSource = 'lang', children } = props;
  const { getValues, getFieldState } = useFormContext();
  const source = 'locales';
  const localesData = getValues(source);

  const { error } = getFieldState(source);

  const locales = Object.values(Locale);

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, _value: number) => {
    setValue(_value);
  };

  const mapTabLabels = () => {
    return locales.map((locale, index) => (
      <Tab style={{ color: error?.[index] ? 'red' : '' }} label={locale} value={index} key={locale} />
    ));
  };

  const mapTabContent = () => {
    return locales.map((locale, index) => {
      const normalizedLocalesData = Array.isArray(localesData) ? localesData : [];

      let localeIndex = normalizedLocalesData.findIndex((localeData) => localeData[langSource] === locale);

      if (localeIndex === -1) {
        // Вместо index — используем правильный способ подставить пустую локаль
        localeIndex = normalizedLocalesData.length; // Добавляем в конец массива
      }

      const currentLocaleSource = `${source}[${localeIndex}]`;

      return (
        <Box display={value === index ? 'flex' : 'none'} flexDirection="column" key={source + locale}>
          <TextInput
            style={{ visibility: 'hidden', height: 0, width: 0 }}
            source={`${currentLocaleSource}.${langSource}`}
            defaultValue={locale}
          />
          {Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return null;
            }

            const fieldSource = `${currentLocaleSource}.${child.props.source}`;

            return cloneElement(child, {
              ...child.props,
              source: fieldSource,
              label: child.props.label ?? capitalize(child.props.source),
              inputProps: {
                ...child.props?.inputProps,
                lang: locale,
              },
            });
          })}
        </Box>
      );
    });
  };

  return (
    <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 2, padding: 1, mb: 2 }} minWidth="50%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {mapTabLabels()}
        </Tabs>
      </Box>

      {mapTabContent()}
    </Box>
  );
};
