import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_LOCALE, Locale } from '../variables';

/**
 * Костыль для обновления имени в поле `name` при изменении `locales[0].name`.
 *
 * Они должны отправляться одинаковыми, иначе запрос вернёт ошибку.
 */
export const useUpdateName = () => {
  const { setValue, getValues } = useFormContext();

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.lang === Locale.EN) {
      const newValue = e.target.value;
      const locales = getValues('locales');
      const defaultLocaleName = getValues('name');

      locales.forEach(({ locale, name }, index) => {
        if (locale !== DEFAULT_LOCALE && (!name || name === defaultLocaleName)) {
          setValue(`locales[${index}].name`, newValue, { shouldDirty: true });
        }
      });

      setValue('name', newValue, { shouldDirty: true });
    }
  };

  return handleChangeName;
};
